import { FaRegSquare } from 'react-icons/fa'
// import { FaRegSquare } from 'react-icons/bs'

export const generateEye = (dayEye1) => {
  //   console.log(dayEye1)
  const drops = dayEye1

  const duration1 = 8
  const aWeek = 7

  const eyeDrops = () => {
    if (drops === 0) {
      return <span></span>
    }
    // if (drops === undefined) {
    //   return <span></span>
    // }
    else if (drops < duration1 + 1) {
      return (
        <div className="mt-0.5  grid place-content-between">
          <div className="flex items-center ">
            <div className="bg-neutral-100 ">
              <FaRegSquare size={20} />
            </div>

            <span className="mr-4"> </span>
            <div className="bg-neutral-100">
              <FaRegSquare size={20} />
            </div>
            <span className="mr-4"> </span>
            <div className="bg-neutral-100">
              <FaRegSquare size={20} />
            </div>
            <span className="mr-4"> </span>
            <div className="bg-neutral-100">
              <FaRegSquare size={20} />
            </div>
          </div>
        </div>
      )
    } else if (drops > duration1 && drops < duration1 + aWeek + 1) {
      return (
        <div className="mt-0.5 grid place-content-between">
          <div className="flex items-center">
            <span className="mr-2"> </span>
            <div className="bg-neutral-100">
              <FaRegSquare size={20} />
            </div>
            <span className="mr-6"> </span>
            <div className="bg-neutral-100">
              <FaRegSquare size={20} />
            </div>
            <span className="mr-6"> </span>
            <div className="bg-neutral-100">
              <FaRegSquare size={20} />
            </div>
          </div>
        </div>
      )
    } else if (drops > duration1 + aWeek && drops < duration1 + aWeek * 2 + 1) {
      return (
        <div className="mt-0.5 grid place-content-center">
          <div className="flex items-center">
            <div className="bg-neutral-100">
              <FaRegSquare size={20} />
            </div>{' '}
            <span className="mr-12"> </span>
            <div className="bg-neutral-100">
              <FaRegSquare size={20} />
            </div>
          </div>
        </div>
      )
    }
    if (drops > duration1 + aWeek * 2 && drops < duration1 + aWeek * 3 + 1) {
      return (
        <div className="mt-0.5 grid place-content-center">
          <div className="bg-neutral-100">
            <FaRegSquare size={20} />
          </div>
        </div>
      )
    } else {
      return <div></div>
    }
  }

  return <div>{eyeDrops()}</div>
}
