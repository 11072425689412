import dayjs from 'dayjs'
import React, { useState } from 'react'
// import React, { useRef, useState } from 'react'
import { generateDateArray } from './util/calendar'
import cn from './util/cn'
import { generateEye } from './util/eye'
// import { GrFormNext, GrFormPrevious } from 'react-icons/gr'
import { Select } from 'evergreen-ui'
import { BsEye } from 'react-icons/bs'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'

// import Login from './util/login'

export default function Calendar() {
  const weekDayHeader = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]

  // build a dayjs() M w/genericized h/m/s/m
  const currentDate = dayjs()
    .set('hour', 1)
    .set('minute', 1)
    .set('second', 1)
    .set('millisecond', 1)
  // const formatCurrentDate = currentDate.format('YYYY-MM-DD')
  // start1.format('DD/MM/YYYY')

  // const onEyeOptionChange = (e) => {
  //   seteyeOrder(e.target.value)
  // }

  // eye1 & eye2 --> 'RIGHT EYE' || 'LEFT EYE' , inits weird
  const [eye1, setEye1] = useState('R or L Eye?')
  const [eye2, setEye2] = useState(' ')

  const [eyeOrder, seteyeOrder] = useState('Nada')
  const [eye2Order, seteye2Order] = useState(' ')

  const onEye2OptionChange = (e) => {
    if (e.target.value === eye2Order) {
      seteye2Order(' ')
      setEye2(' ')
      setStart2(dayjs(-1))
      generateDateArray(start1, startEnd1, start2, eye1, eye2)
    } else {
      seteye2Order(e.target.value)
      if (eye1 === 'Left Eye') {
        setEye2('Right Eye')
      } else {
        setEye2('Left Eye')
      }
    }
  }

  // const [today, setToday] = useState(currentDate)

  // DatePicker input field
  const [dateDP, setDateDP] = useState(new Date())

  // const [selectDate, setSelectDate] = useState(currentDate)

  // start dates for eye1 & eye2
  // default eye1 is genericized today's date
  // default eye2 is rando date in far past
  const [start1, setStart1] = useState(currentDate)
  const [start2, setStart2] = useState(dayjs(-1))
  // set end dates for eye1 & eye2, fixed 31 can be input var instead
  const totalDays = 29
  const startEnd1 = start1.add(totalDays - 1, 'day')
  const startEnd2 = start2.add(totalDays - 1, 'day')
  const surgeryDay1 = start1.add(0, 'day')
  const surgeryDay2 = start2.add(0, 'day')

  const max2CalDays70 = 70

  // for an onClick action cal date:
  // const onChangestart1 = ({ target }) => {
  //   setStart1(dayjs(target.value))
  //   setSelectDate(dayjs(target.value))
  // }

  const eyeOneDateChange = (date1) => {
    // set start1 genericized date h/m/s/m -- to be used w/ eye1
    setStart1(
      dayjs(date1)
        .set('hour', 1)
        .set('minute', 1)
        .set('second', 1)
        .set('millisecond', 1)
    )
    // for an onClick action cal date:
    // set selectDate genericized date h/m/s/m --
    // setSelectDate(
    //   dayjs(date1)
    //     .set('hour', 1)
    //     .set('minute', 1)
    //     .set('second', 1)
    //     .set('millisecond', 1)
    // )

    // set date -for DatePicker input field to start1 date //eye1// (so user not confused)
    setDateDP(date1)
  }

  const [eye2Error, setEye2Error] = useState('noError')

  const eyeTwoDateChange = (date2) => {
    setStart2(
      dayjs(date2)
        .set('hour', 1)
        .set('minute', 1)
        .set('second', 1)
        .set('millisecond', 1)
    )
    // set date -for DatePicker2 input field to blank (so user not confused)
    setDateDP(null)

    if (
      start1.day() + 1 + dayjs(date2).add(totalDays, 'day').diff(start1, 'd') >
      max2CalDays70 - 1
    ) {
      console.log('diff too big!!!!  MAX DIFF DAYS: ')
      seteye2Order(' ')
      setEye2Error('error')
      // seteye2Order(' ')
      console.log('eye2Error !!!!!!!!!~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
      console.log(eye2Error)
      // setEye2(' ')
    } else {
      console.log('fits 2 Cal Days')
    }
  }

  const startOver = () => {
    setEye1('R or L Eye?')
    setEye2(' ')
    setStart1(currentDate)
    setStart2(dayjs(-1))
    seteyeOrder('Nada')
    seteye2Order(' ')
    setDateDP(new Date())
    setEye2Error('noError')
  }

  const colorMo = [
    'bg-pink-400',
    'bg-green-200',
    'bg-teal-400',
    'bg-lime-200',
    'bg-orange-400',
    'bg-cyan-200',
    'bg-yellow-400',
    'bg-orange-200',
    'bg-rose-400',
    'bg-yellow-200',
    'bg-blue-400',
    'bg-sky-200',
  ]

  const surgeryColor =
    'border-y-[2px] border-x-[4px] border-neutral-500 bg-stone-50 '
  const priorColor = 'bg-neutral-200 text-gray-400'

  const arrCalDays = generateDateArray(
    start1,
    startEnd1,
    start2,
    eye1,
    eye2,
    totalDays
  )
  // ----------------------------------------------------------------------
  // const maxDatediff = 70 - 64 - start1.day()

  const firstDayHi = start1.subtract(start1.day())
  const lastDateToSchedEye2 = start1
    .subtract(start1.day() + 1, 'day')
    .add(70, 'day')
    .subtract(totalDays - 1, 'day')

  const [isShown, setIsShown] = useState(false)
  // const optimisedHandleChange = debounce(handleChange, 30)
  return (
    <div>
      <div>
        <div>
          <div id="main" className="p-3">
            {console.log(firstDayHi)}
            {console.log(lastDateToSchedEye2)}

            <div id="hideMe">
              {start1.day() + start2.add(totalDays, 'day').diff(start1, 'd') >
              max2CalDays70 ? (
                <div
                  className="grid place-content-center font-bold text-[#f22c1d] text-2xl mb-7 mt-5  caret-transparent"
                  id="eye2Error"
                >
                  <div>
                    Eyedrop schedules for the two eyes are too far apart.
                  </div>
                  <div className="grid place-content-center ">
                    Create two separate charts.
                  </div>
                  {/* <div
                      className=" font-bold text-red-400 text-xl pl-190 mb-4"
                      id="eye2Error2"
                    >
                      &nbsp; if 2nd Eye start date is after{' '}
                      {lastDateToSchedEye2.toDate().toDateString()}
                    </div> */}
                </div>
              ) : (
                ''
              )}
              <div id="eyeEntries" className="caret-transparent mb-2.5 ">
                <div className="relative inline-flex" div id="radioEyes">
                  <div className="font-bold mb-2.5">
                    {eyeOrder === 'Nada' ? (
                      <span>FIRST EYE or SECOND EYE?</span>
                    ) : (
                      ''
                    )}
                    {eyeOrder === 'second' ? (
                      ''
                    ) : (
                      <input
                        className="ml-5"
                        type="radio"
                        name="eyeOrder"
                        value="first"
                        id="first"
                        checked={eyeOrder === 'first'}
                        onChange={(e) => seteyeOrder(e.target.value)}
                      />
                    )}
                    {eyeOrder === 'second' ? (
                      ''
                    ) : (
                      <label htmlFor="first"> 1st Eye</label>
                    )}
                    {eyeOrder === 'first' ? (
                      ''
                    ) : (
                      <input
                        className="ml-5"
                        type="radio"
                        name="eyeOrder"
                        value="second"
                        id="second"
                        checked={eyeOrder === 'second'}
                        onChange={(e) => seteyeOrder(e.target.value)}
                      />
                    )}
                    {eyeOrder === 'first' ? (
                      ''
                    ) : (
                      <label htmlFor="second"> 2nd Eye</label>
                    )}
                  </div>
                  <div className="font-bold mb-2 ml-20">
                    {eyeOrder !== 'second' &&
                    (eye1 === 'Right Eye' || eye1 === 'Left Eye') ? (
                      <span className="ml-40">Add SECOND EYE?</span>
                    ) : (
                      ''
                    )}
                    {eyeOrder !== 'second' &&
                    (eye1 === 'Right Eye' || eye1 === 'Left Eye') ? (
                      <input
                        className="ml-2 w-7 h-7 [clip-path:circle(40%_at_49%_48%)] text-blue-600 bg-gradient-to-r from-cyan-50 to-blue-400 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-blue-800 focus:ring-2 dark:bg-blue-700 dark:border-blue-600"
                        type="checkbox"
                        name="eye2Order"
                        value="second2"
                        id="second2"
                        checked={eye2Order === 'second2'}
                        onChange={onEye2OptionChange}
                      />
                    ) : (
                      ''
                    )}
                    {eyeOrder !== 'second' &&
                    (eye1 === 'Right Eye' || eye1 === 'Left Eye') ? (
                      <label htmlFor="second"> 2nd Eye</label>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className=" flex flex-row ">
                  <div className="flex basis-2/6" id="eyeAndName1">
                    {eyeOrder === 'Nada' ? (
                      ''
                    ) : (
                      <div id="h4-option-select-1 text-base">
                        <Select
                          height={35}
                          className="caret-transparent "
                          value={eye1}
                          onChange={(e) => setEye1(e.target.value)}
                        >
                          <option value="Which Eye?">{eye1}</option>
                          <option value="Right Eye">Right Eye</option>
                          <option value="Left Eye">Left Eye</option>
                        </Select>
                      </div>
                    )}
                    {eye1 === 'R or L Eye?' ? (
                      ''
                    ) : (
                      <span className="ml-3 pt-3 font-bold text-blue-700">
                        {' '}
                        Start Date:
                      </span>
                    )}
                    {eye1 === 'R or L Eye?' ? (
                      ''
                    ) : (
                      <div id="e1Date" className="text-sm ml-1">
                        <DatePicker
                          className="text-sm -p-1 border-1 rounded border-neutral-200 text-gray-600 h-9 w-24"
                          selected={dateDP}
                          onChange={eyeOneDateChange}
                          dateFormat="MM/dd/yy"
                        />
                      </div>
                    )}
                  </div>
                  <div className=" flex basis-3/6 ml-9" id="eyeAndName2">
                    <div
                      id="eye2select"
                      className="h4-option-select-2 relative inline-flex"
                    >
                      {eye2Order === 'second2' ? (
                        <Select
                          height={35}
                          className="caret-transparent"
                          value={eye2}
                          onChange={(e) => setEye2(e.target.value)}
                        >
                          {eye1 === 'Right Eye' ? (
                            <option value="Left Eye">Left Eye</option>
                          ) : (
                            <option value="Right Eye">Right Eye</option>
                          )}
                        </Select>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="flex relative inline-flex">
                      {eye2Order !== 'second2' ? (
                        ''
                      ) : (
                        <span className="ml-3 pt-3 font-bold text-blue-700 ">
                          Start Date:
                        </span>
                      )}
                      {eye2Order !== 'second2' ? (
                        ''
                      ) : (
                        <div
                          id="e2Date"
                          className="text-sm ml-1 flex relative inline-flex"
                        >
                          <DatePicker
                            className="text-sm -p-1 border-1 rounded border-neutral-200 text-gray-600 h-9 w-24"
                            selected={dateDP}
                            onChange={eyeTwoDateChange}
                            dateFormat="MM/dd/yy"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="-mt-9 mb-3 basis-1/6 space-x-1 bg-white  rounded">
                    <button
                      type="button"
                      onClick={startOver}
                      // relative inline-flex
                      className="text-sm sm:text-base rounded-full font-medium border-2 border-transparent transition-colors outline-transparent focus:outline-transparent disabled:opacity-50 disabled:pointer-events-none disabled:opacity-40 disabled:hover:opacity-40 disabled:cursor-not-allowed disabled:shadow-none
          text-white bg-[#4646fa] hover:bg-[#3333D1] focus:border-[#B3B3FD] focus:bg-[#4040F2] px-4 py-1 sm:py-1.5 sm:px-5"
                    >
                      Reset
                    </button>
                  </div>
                  {isShown ? (
                    <div className="-mt-9 mb-3 basis-2/6 text-gray-700 ">
                      In print dialog box, select{' '}
                      <span className=" font-bold">"Background Graphics" </span>
                      option to print colors...
                    </div>
                  ) : (
                    <div className="-mt-9 mb-3 basis-2/6"></div>
                  )}

                  <div className="-mt-9 mb-3 basis-1/6 space-x-1 bg-white  rounded">
                    <button
                      type="button"
                      onClick={() => window.print()}
                      // onClick={() => window.print()}
                      // relative inline-flex
                      onMouseEnter={() => setIsShown(true)}
                      onMouseLeave={() => setIsShown(false)}
                      className="text-sm sm:text-base rounded-full font-medium border-2 border-transparent transition-colors outline-transparent focus:outline-transparent disabled:opacity-50 disabled:pointer-events-none disabled:opacity-40 disabled:hover:opacity-40 disabled:cursor-not-allowed disabled:shadow-none
          text-white bg-[#4040F2] hover:bg-[#3333D1] focus:border-[#B3B3FD] focus:bg-[#4040F2] px-4 py-1 sm:py-1.5 sm:px-5"
                    >
                      Print
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div id="eyeRangesCal1 ">
              {eyeOrder === 'Nada' || eye1 === 'R or L Eye?' ? (
                ''
              ) : (
                <div
                  id="header1Cal"
                  className="flex ml-12  font-bold text-gray-700 italic text-base"
                >
                  <BsEye size={20} />
                  <div className="flex-none">
                    &nbsp;
                    {eye1}:&nbsp;
                  </div>
                  <div className="flex-none font-semibold text-gray-600">
                    {start1.toDate().toDateString()}&nbsp;{' to '}&nbsp;
                    {startEnd1.toDate().toDateString()}
                  </div>
                  <div>&nbsp; &nbsp; &nbsp; &nbsp;</div>
                  <div>
                    {eye2 === ' ' ||
                    start1.day() +
                      start2.add(totalDays, 'day').diff(start1, 'd') >
                      max2CalDays70 ||
                    start2 < start1 ? (
                      ''
                    ) : (
                      <div className="relative inline-flex ">
                        <BsEye size={20} />
                        <div className="flex-none">&nbsp; {eye2}:&nbsp; </div>
                        <span className="flex-none font-semibold text-gray-600">
                          {start2.toDate().toDateString()}&nbsp;{' to '}
                          &nbsp;
                          {startEnd2.toDate().toDateString()}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div
              className=" grid lg:grid-cols-7 print:grid-cols-7 md:grid-cols-3   border-[2px] border-neutral-500 mt2 mt-0.5 ml-7 mr-7 mb-0.5"
              id="calDays"
            >
              {arrCalDays.map(
                (
                  {
                    date,
                    prevDay,
                    start1,
                    hasEye1,
                    hasEye2,
                    dayEye1,
                    dayEye2,
                    arrI,
                  },
                  index
                ) => {
                  return arrI < 35 ? (
                    <div
                      key={index}
                      className="grid h-[142px] w-full place-content-top border-neutral-900 border-2 "
                    >
                      <div
                        className={cn(
                          // selectDate.toDate().toDateString() ===
                          //   date.toDate().toDateString()
                          //   ? 'bg-black text-white'
                          //   : '',
                          // start1 ? 'bg-red-600 text-white' : '',
                          // 'hover:bg-black hover:text-white transition-all cursor-pointer',
                          'select-none ',

                          date.day() === 4
                            ? 'border-l-[2px] border-black  '
                            : '',

                          !hasEye1 && !hasEye2
                            ? priorColor
                            : surgeryDay1.isSame(date) ||
                              surgeryDay2.isSame(date)
                            ? surgeryColor
                            : colorMo[date.month()]
                          // date.month() % 2 === 0 ? 'text-gray-600' : '',
                        )}
                        onClick={() => {
                          // setSelectDate(date)
                        }}
                      >
                        {/* <span className="text-xs">{mos[date.month()]}</span> */}
                        <div>
                          <div className="grid place-content-center text-xl font-bold -mt-[2px]">
                            {date.date()} &nbsp;
                            {dayjs(date).format('MMMM')}
                            <span className="text-base grid place-content-center -mt-1.5">
                              {weekDayHeader[date.day()]}
                            </span>
                          </div>
                          <div className="grid place-content-center  text-sm -mt-[4px]">
                            <div className="  grid place-content-center">
                              {hasEye1 ? (
                                eye1
                              ) : (
                                <div className="mt-1.5 pt-9"></div>
                              )}
                              {hasEye1 && date.isSame(surgeryDay1)
                                ? ' (Surgery)'
                                : ''}
                            </div>
                            {generateEye(dayEye1)}
                            <div className="pt-1 grid place-content-center">
                              {' '}
                              {hasEye2 ? eye2 : ''}
                              {hasEye2 && date.isSame(surgeryDay2)
                                ? ' (Surgery)'
                                : ''}
                            </div>
                            {generateEye(dayEye2)}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )
                }
              )}
            </div>
            {arrCalDays.length < 35 || arrCalDays.length > 70 ? (
              ''
            ) : (
              <div className="mt-2 " id="cal2Needed">
                <div id="eyeRangesCal2">
                  <div className="ml-12 font-bold text-gray-700 italic text-base">
                    <div>
                      {eye2 === ' ' ||
                      start1.day() +
                        start2.add(totalDays, 'day').diff(start1, 'd') >
                        max2CalDays70 - 1 ||
                      start2 < start1 ||
                      arrCalDays.length < 36 ? (
                        ''
                      ) : (
                        <div className="flex ">
                          <BsEye size={20} />
                          <div className="flex">
                            <div className="flex-none">
                              &nbsp;
                              {eye2}:&nbsp;
                            </div>
                            <div className="flex-none font-semibold text-gray-600">
                              {start2.toDate().toDateString()}&nbsp;{' to '}
                              &nbsp;
                              {startEnd2.toDate().toDateString()}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={cn(
                    arrCalDays.length < 36
                      ? 'border-0 '
                      : 'border-[2px] border-neutral-500',
                    'grid lg:grid-cols-7 print:grid-cols-7 md:grid-cols-3      mt2 mt-0.5 ml-7 mr-7 mb-0.5'
                  )}
                >
                  {arrCalDays.map(
                    (
                      {
                        date,
                        prevDay,
                        start1,
                        hasEye1,
                        hasEye2,
                        dayEye1,
                        dayEye2,
                        arrI,
                      },
                      index
                    ) => {
                      return arrI > 34 && arrI < max2CalDays70 ? (
                        <div
                          key={index}
                          className=" grid h-[142px] w-full place-content-top border-neutral-900 border-2 "
                        >
                          <div
                            className={cn(
                              // selectDate.toDate().toDateString() ===
                              //   date.toDate().toDateString()
                              //   ? 'bg-black text-white'
                              //   : '',
                              // start1 ? 'bg-red-600 text-white' : '',
                              // 'hover:bg-black hover:text-white transition-all cursor-pointer',
                              'select-none ',

                              date.day() === 4
                                ? 'border-l-[2px] border-black  '
                                : '',

                              !hasEye1 && !hasEye2
                                ? priorColor
                                : surgeryDay1.isSame(date) ||
                                  surgeryDay2.isSame(date)
                                ? surgeryColor
                                : colorMo[date.month()]
                              // date.month() % 2 === 0 ? 'text-gray-600' : '',
                            )}
                            onClick={() => {
                              // setSelectDate(date)
                            }}
                          >
                            {/* <span className="text-xs">{mos[date.month()]}</span> */}
                            <div>
                              <div className="grid place-content-center text-xl font-bold -mt-[2px]">
                                {date.date()} &nbsp;
                                {dayjs(date).format('MMMM')}
                                <span className="text-base grid place-content-center -mt-[4px]">
                                  {weekDayHeader[date.day()]}
                                </span>
                              </div>
                              <div className="grid place-content-center  text-sm ">
                                <span className="  grid place-content-center">
                                  {hasEye1 ? (
                                    eye1
                                  ) : (
                                    <span className="mt-1.5 pt-8"></span>
                                  )}
                                </span>
                                {generateEye(dayEye1)}
                                <div className="pt-1 grid place-content-center">
                                  {hasEye2 ? eye2 : ''}
                                </div>
                                {generateEye(dayEye2)}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )
                    }
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
