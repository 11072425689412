import dayjs from 'dayjs'

// start1.millisecond(),
// start1.second(),
// start1.minute(),
// start1.hour(),
// start1.month(),
// start1.year(),
//--------------
// start1.date(),
//--------------
// start1,
// startEnd1,
// start2,
//--------------
// eye1,
// eye2

export const generateDateArray = (
  //--------------
  start1 = dayjs(),
  startEnd1 = dayjs(),
  start2 = dayjs(),
  eyeOne = String,
  eyeTwo = String,
  totDays = Number
) => {
  // console.log('eyeOne :')
  // console.log(eyeOne)
  // console.log('eyeTwo :')
  // console.log(eyeTwo)
  // console.log('start2 :')
  // console.log(start2)

  console.log('start1  has the full date!')
  console.log(start1)
  // console.log('start1.date()  shows the day # ONLY, ie 14 for 2/14/23')
  // console.log(start1.date())
  // console.log('-start1.day()  shows ??? for 2/14/23')
  // console.log(start1.day())

  //------------------------------------------------------------
  const arrayOfDate = []

  // create prefix date
  // start1.DAY()  is the day-of-week #0-6 of the const date1
  // 1-Mon 2-Tues 3-Weds 4-Thurs 5-Fri 6-Sat 0-Sun (if 0-Sun no run no prevys)
  //  .day( )  parmy takes in #0-6
  // returns date m{} of day of Week *relationally* to const date1
  // will get index 1 thru 6 days-of-week Mon-Sat, as needed to populate days prior to const date in 1st week
  for (let i = 0; i < start1.day(); i++) {
    // increments from zero to the day-of-week #1-7
    const date = start1.day(i)
    // start of calendar array -- fill-in prev days (if any) to orient user
    arrayOfDate.push({
      hasEye1: false,
      hasEye2: false,
      dayEye1: 0,
      dayEye2: 0,
      prevDay: true,
      date,
    })
  }

  //------------------------------------------------------------
  // push to arr    date1 plus 31 days
  // k:v's and checks for 'today'
  for (let i = 0; i <= totDays - 1; i++) {
    arrayOfDate.push({
      hasEye1: true,
      hasEye2: false,
      dayEye1: i + 1,
      dayEye2: 0,
      date: start1.add(i, 'day'),
      today:
        start1.date(i).toDate().toDateString() ===
        dayjs().toDate().toDateString(),
    })
  }

  //------------------------------------------------------------
  // get eye2 end date (if any)
  // no add'l days if outside range of 2 cal pgs! [70 days]
  const lastDateEye2 = start2.isBefore(start1)
    ? startEnd1
    : // prev days + (start2End-start1) > 70 max 2Cal days ? N/A 2+ Cal pgs
    start1.day() + start2.add(totDays - 1, 'day').diff(start1, 'd') < 70
    ? start2.add(totDays - 1, 'day')
    : startEnd1
  console.log('lastDateEye2')
  console.log(lastDateEye2)

  // calc #of days btwn eye1 endDate & eye2 endDate
  // to get add'l cal days needed for eye2 /if any
  const diffy = lastDateEye2.diff(startEnd1, 'd')

  //------------- add cal days to arr: startEnd1 thru lastDateEye2 -------------------------------------------
  // not eye1
  for (let i = 1; i <= diffy; i++) {
    arrayOfDate.push({
      date: startEnd1.add(i, 'day'),
      hasEye1: false,
      hellodiffy: 'hi diffy',
    })
  }
  //------------------------------------------------------------

  let eye2index =
    start1.day() + start2.add(totDays - 1, 'day').diff(start1, 'd') < 70
      ? arrayOfDate.length - 1
      : start1.day() + totDays
  console.log('eye2index')
  console.log(eye2index)
  console.log(arrayOfDate)

  //  if no eye2 or cal days exceed max2CalDays70 [arr i>69] than um... blurp!
  if (
    eyeTwo === ' ' ||
    start1.day() + start2.add(totDays - 1, 'day').diff(start1, 'd') > 69
  ) {
    arrayOfDate[eye2index - 1].hasEye2 = false
    //
  } else {
    let eye2Daycount = totDays + 1
    //  eye2 #of days -- [ie. arrayOfDate index # ]
    //  eye2 els : true & set eye2 day# (1-32)

    for (let i = eye2index; i > eye2index - totDays; i--) {
      arrayOfDate[i].hasEye2 = true
      arrayOfDate[i].dayEye2 = eye2Daycount = eye2Daycount - 1
    }
  }

  // add k:v w/index #
  arrayOfDate.forEach(function (el, idx) {
    el.arrI = idx
  })

  return arrayOfDate
}

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
